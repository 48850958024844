import React from "react";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
const logo = require("./logo.png");

const Home: React.FC = () => {
  return (
    <Grid
      container
      sx={{ height: "100%" }}
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Grid item>
        <img
          style={{ marginRight: "10px" }}
          width="250px"
          src={logo}
          alt="CLARIFYDATA"
        />
      </Grid>
      <Grid item>
        <div
          style={{
            fontSize: "60pt",
            fontFamily: "PoppinsBold",
            display: "flex",
          }}
        >
          <div style={{ color: "#474746" }}>CD.</div>
          <div style={{ color: "#1f83be" }}>PLATFORM</div>
        </div>
      </Grid>
      <Grid item>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <a href="http://datentransfer.cdplatform.de">CD.DATENTRANSFER</a>
          </Grid>
          <Grid item>
            <Link id="link" to="/impressum">
              Impressum
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Home;
