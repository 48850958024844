import React from "react";

const Impressum: React.FC = () => {
  return (
    <div style={{ paddingLeft: "48px", paddingTop: "48px" }}>
      <h1>Impressum</h1>
      <h2>
        <b>Angaben gemäß § 5 TMG:</b>
      </h2>

      <ul style={{ listStyle: "none", paddingLeft: 0 }}>
        <li>clarifydata GmbH</li>
        <li>Fleethörn 7</li>
        <li>24103 Kiel</li>
        <br />
        <li>Handelsregister: HRB 18450 KI</li>
        <li>Registergericht: Amtsgericht Kiel</li>
        <br />
        <li>USt-IdNr.: DE312167268</li>
        <br />
        <li>Vertreten durch: Lars Michael Hartke</li>
      </ul>

      <h2>
        <b>Kontakt</b>
      </h2>
      <ul style={{ listStyle: "none", paddingLeft: 0 }}>
        <li>Telefon: 0431-55685313</li>
        <li>E-Mail: moin@clarifydata.de</li>
      </ul>
      <h2>
        <b>Datenschutzbeauftragter</b>
      </h2>
      <ul style={{ listStyle: "none", paddingLeft: 0 }}>
        <li>Dr. Johannes Raabe</li>
      </ul>
    </div>
  );
};

export default Impressum;
